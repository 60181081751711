import {Component, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';

@Component({
	selector: 'app-select-language-modal',
	templateUrl: './select-language-modal.component.html',
	styleUrls: ['./select-language-modal.component.scss']
})
export class SelectLanguageModalComponent implements OnInit {
	public selectedLanguage: string;
	public listCountries = [
		{
			name: 'Deutsch',
			icon: '../../../assets/lang/de.svg',
			value: 'de'
		},

		{
			name: 'English',
			icon: '../../../assets/lang/en.svg',
			value: 'en'
		},

		{
			name: 'France',
			icon: '../../../assets/lang/fr.svg',
			value: 'fr'
		}
	];
	constructor(
		private translate: TranslateService,
		private modalCtrl: ModalController
	) {
		this.selectedLanguage = this.translate.currentLang;
	}
	static async show(modalCtrl: ModalController) {
		const modal = await modalCtrl.create({
			component: SelectLanguageModalComponent,
			cssClass: 'short-info-modal auto-height',

			showBackdrop: true,
			backdropDismiss: true
		});
		await modal.present();
	}
	ngOnInit() {}
	selectLng(lang: string) {
		this.selectedLanguage = lang;
		// this.translate.setDefaultLang(this.selectedLanguage);
		// this.translate.use(this.selectedLanguage);
		// this.dismiss();
	}
	dismissWithData() {
		this.translate.setDefaultLang(this.selectedLanguage);
		this.translate.use(this.selectedLanguage);
		this.dismiss();
	}
	dismiss() {
		this.modalCtrl.dismiss();
	}
}
