import {TranslateService} from '@ngx-translate/core';
import {Component, OnInit} from '@angular/core';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';
import RepositoryDirective from '../../../smoothr-web-app-core/directives/repository-directive';
import {environment} from '../../../environments/environment';
import {PreorderType} from '../../../smoothr-web-app-core/enums/PreorderType';
import {isVenueOpen, sleep} from '../../../smoothr-web-app-core/utils/utils';
import Venue from '../../../smoothr-web-app-core/models/Venue';
import Address from '../../../smoothr-web-app-core/models/Address';
import {ModalController} from '@ionic/angular';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute, Router} from '@angular/router';
import {Api} from 'src/smoothr-web-app-core/api/api';
import {BehaviorSubject} from 'rxjs';
import {TimeUtils} from 'src/smoothr-web-app-core/utils/time-utils';
import {WorkingHoursModalComponent} from 'src/app/components/working-hours-modal/working-hours-modal.component';
import {MenuPage} from '../menu/menu.page';
import {AngularFireAnalytics} from '@angular/fire/compat/analytics';
import {HomePage} from '../home/home.page';
import {MapsUtils, calculateGeoDistance} from 'src/smoothr-web-app-core/utils/maps-utils';
import {ShortLocationErrorModalComponent} from 'src/app/components/short-location-error-modal/short-location-error-modal.component';
import {ShortLocationNotificationModalComponent} from 'src/app/components/short-location-notification-modal/short-location-notification-modal.component';

enum Language {
	GreatBritain = 'en',
	German = 'de'
}

@Component({
	selector: 'app-select-venue',
	templateUrl: './select-venue.page.html',
	styleUrls: ['./select-venue.page.scss']
})
export class SelectVenuePage extends RepositoryDirective implements OnInit {
	static url = 'select-venue';
	// static scanQr = 'scan-qr/:venueId/:tableId';
	environment = environment;
	languageEnum = Language;

	loading = false;
	preorderType = PreorderType;
	active: any = {};
	loading$ = new BehaviorSubject(false);
	public relevantVenues: Venue[] = [];
	isVenueOpen = isVenueOpen;

	acceptsDelivery = false;
	firstDeliveryTime: string = null;
	acceptsInside = false;
	firstInsideTime: string = null;
	acceptsTakeaway = false;
	firstTakeAwayTime: string = null;

	constructor(
		protected repository: RepositoryService,
		public translate: TranslateService,
		private modalCtrl: ModalController,
		private snackbarCtrl: MatSnackBar,
		private router: Router
	) {
		super(repository);
		this.loading$.next(false);
	}

	static navigate(router: Router) {
		return router.navigateByUrl(SelectVenuePage.url);
	}

	static async show(modalCtrl: ModalController, venue: Venue): Promise<boolean> {
		const modal = await modalCtrl.create({
			component: SelectVenuePage,
			cssClass: 'select-venue-modal',
			componentProps: {
				relevantVenues: [venue]
			},
			showBackdrop: true,
			backdropDismiss: true
		});
		await modal.present();
		const response = await modal.onDidDismiss();
		return response.data;
	}

	ngOnInit() {
		super.ngOnInit();
	}

	async ionViewDidEnter() {
		if (history.state.navigationId === 1) {
			this.repository.order.emit(null);
		}
		this.repository.verifiedOrder.emit(null);
		await this.getAdress();

		// if (!this.repository._address) {
		// 	this.relevantVenues = [];
		// 	await ShortLocationErrorModalComponent.show(this.modalCtrl);
		// 	return;
		// }

		// this.locateAndShowStores();
	}

	async loadSelectedVenueWithTableId(venueId: string, tableId: string) {
		try {
			this.loading$.next(true);
			const venue = await this.repository.getVenue(venueId);
			const table = (await Api.getTable(this.venue._id, tableId)).data;
			const isOpenResturant = TimeUtils.doesHoursMatchNow(venue?.openingHours);
			if (!isOpenResturant) {
				this.loading$.next(false);
				this.snackbarCtrl.open(this.translate.instant('home_page.venue_closed'), null, {
					duration: 5000
				});
				return;
			}
			if (venue && table) {
				this.relevantVenues = [venue];
				this.repository.venue.emit(venue);
				this.repository.createOrder(this.venue, null, PreorderType.INSIDE);
				this.order.tableNumber = table.number;
				this.order.table = table._id;
				this.repository.order.emit(this.order);
				sleep(200);
				this.loading$.next(false);

				// await MenuPage.navigate(this.router);
			}
			// await this.selectVenue(venue);
		} catch (e) {
			this.loading$.next(false);
		}
	}
	async loadSelectedVenue(venueId: string) {
		try {
			this.loading$.next(true);
			const venue = await this.repository.getVenue(venueId);
			if (venue) {
				this.relevantVenues = [venue];
			}
		} catch (error) {
			// await this.locateAndShowStores();
		}
		// await this.selectVenue(venue);
		this.loading$.next(false);
	}

	async getAdress() {
		try {
			const address = await MapsUtils.getUserGeocode();
			this.repository.address.emit(address);
			console.log(address);
		} catch (e) {
			console.error(e);
			this.repository.address.emit(null);
		}
	}

	async locateAndShowStores() {
		this.loading$.next(true);
		const venues = await this.repository.getAllVenues();
		if (venues.length > 0) {
			this.relevantVenues = venues;
			if (this.repository._address?.lat && this.repository._address?.lng) {
				const sortedVenues = [...this.relevantVenues].sort((venueA, venueB) => {
					const distanceA = this.calcDistance(venueA);
					const distanceB = this.calcDistance(venueB);

					return distanceA - distanceB;
				});

				this.relevantVenues = sortedVenues;
			}
		}
		this.loading$.next(false);
	}

	async loadVenueAndCreateOrder(venue: Venue, address: Address, preorderType: PreorderType) {
		const loadedVenue = await this.repository.getVenue(venue._id);
		this.repository.createOrder(loadedVenue, address, preorderType);
		// await this.navigationService.menu();
		this.loading$.next(false);
	}

	calcDistance(venue: Venue) {
		let distance = 0;

		if (!this.repository._address?.lat || !this.repository._address?.lng || !venue.location.coordinates.length) {
			return distance;
		}

		distance = calculateGeoDistance(
			venue.location.coordinates[0],
			venue.location.coordinates[1],
			this.repository._address?.lat,
			this.repository._address?.lng
		);

		return distance;
	}

	changeLang(language: string) {
		this.translate.setDefaultLang(language);
		this.translate.use(language);
	}
	async loadVenueByQrCode(venueId: string, tableNumber: string) {
		try {
			this.loading$.next(true);
			const loadedVenue = await this.repository.getVenue(venueId);

			if (!loadedVenue) {
				console.log('No available venues');
				this.snackbarCtrl.open('Sorry, this shop doesn`t exist', null, {
					duration: 2000
				});
				this.loading$.next(false);

				return;
			}
			const isOpenResturant = TimeUtils.doesHoursMatchNow(loadedVenue?.openingHours);
			if (!isOpenResturant) {
				this.loading$.next(false);
				this.snackbarCtrl.open(this.translate.instant('home_page.venue_closed'), null, {
					duration: 5000
				});
				return;
			}
			const table = (await Api.getTable(loadedVenue._id, tableNumber)).data;
			await sleep(100);
			if (loadedVenue && table) {
				this.repository.createOrder(loadedVenue, null, PreorderType.INSIDE);
				this.order.tableNumber = table.number;
				this.order.table = table._id;
				this.repository.order.emit(this.order);
				sleep(100);
				// await SelectTableModalComponent.show(this.modalCtrl, this.order);
				// this.navigationService.menu();
			}
			this.loading$.next(false);
		} catch (e) {
			this.snackbarCtrl.open(this.translate.instant('home_page.scan_table_error'), null, {
				duration: 2000
			});
			this.loading$.next(false);
		}
	}
	goBack() {
		HomePage.navigate(this.router);
	}
	isVenueOpenFunc(venue: Venue) {
		return this.isVenueOpen(venue)
			? this.translate.instant('venue_suggestion.open')
			: this.translate.instant('venue_suggestion.close');
	}

	async openInfoPage(venue: Venue) {
		await WorkingHoursModalComponent.show(this.modalCtrl, venue, PreorderType.INSIDE);
	}

	accepts(type: PreorderType): boolean {
		switch (type) {
			case PreorderType.DELIVERY:
				return this.acceptsDelivery;
			case PreorderType.TAKE_AWAY:
				return this.acceptsTakeaway;
			case PreorderType.INSIDE:
				return this.acceptsInside;
			default:
				return false;
		}
	}

	async selectVenue(venue: Venue, type: PreorderType) {
		this.loading$.next(true);
		const isOpenResturant = TimeUtils.doesHoursMatchNow(venue?.openingHours);
		if (!isOpenResturant) {
			this.loading$.next(false);
			this.snackbarCtrl.open(this.translate.instant('home_page.venue_closed'), null, {
				duration: 5000
			});
			return;
		}

		// if (this.calcDistance(venue) >= 10) {
		// 	const result = await ShortLocationNotificationModalComponent.show(this.modalCtrl);

		// 	if (result !== 'success') {
		// 		this.loading$.next(false);
		// 		return;
		// 	}
		// }

		const loadedVenue = await this.repository.getVenue(venue._id);
		console.log(loadedVenue);
		await sleep(100);
		if (!loadedVenue) {
			this.loading$.next(false);
			this.snackbarCtrl.open(this.translate.instant('home_page.venue_closed'), null, {
				duration: 5000
			});
			return;
		}
		this.repository.createOrder(loadedVenue, null, type);
		sleep(100);
		// await MenuPage.navigate(this.router);
		this.modalCtrl.dismiss(true);
	}

	public close() {
		this.modalCtrl.dismiss();
	}
}
